<template>
    <div class="content">
        <h1 class="pagetitle">
            {{ name }}
        </h1>
        <template v-for="(data, key, index) in setting">
            <div v-if="key !== 'updatedAt'" :key="`setting${index}`" :class="$style.item">
                <input v-if="data.use" :id="`setting${index}`" v-model="data.use" type="checkbox" checked>
                <input v-else :id="`setting${index}`" v-model="data.use" type="checkbox">
                <label v-if="getters === 'getManagements'" :for="`setting${index}`">
                    {{ data.title }}
                </label>
                <label v-else-if="getters === 'getModules'" :for="`setting${index}`">
                    {{ data.name }}
                </label>
            </div>
        </template>
        <div class="buttonArea">
            <Button
                text="設定する"
                type="next"
                @action="buttonSetting()"
            />
        </div>
    </div>
</template>

<script>
    "use strict";

    import Button from "@/components/Button.vue";

    import firebase from "@/mixins/firebase.js";
    import setting from "@/mixins/setting.js";

    export default {
        "components": {
            Button,
        },
        "mixins": [
            firebase,
            setting,
        ],
        "props": {
            "getters": {
                "type": String,
                "required": true,
            },
            "name": {
                "type": String,
                "required": true,
            },
        },
        data() {
            return {
                "setting": this.$store.getters[this.getters],
            };
        },
        "watch": {
            $route() {
                this.setting = JSON.parse(JSON.stringify(this.$store.getters[this.getters]));
            },
        },
        created() {
            this.$store.watch(
                (state, getters) => getters[this.getters],
                () => {
                    this.setting = JSON.parse(JSON.stringify(this.$store.getters[this.getters]));
                }
            );
        },
        "methods": {
            buttonSetting() {
                this.$_firestoreUpdate({
                    "collection": "settings",
                    "doc": this.name,
                    "set": this.setting,
                }).then(() => {
                    alert("設定しました！");
                }).catch(error => {
                    alert(error.message);
                });
            },
        },
    };
</script>

<style lang="scss" module>
    .item {
        input {
            &:checked {
                + label {
                    background: none;
                    border-color: $conversion-color;
                    color: $text-color;
                    padding-left: 2.6em;
                    &::before {
                        background: $conversion-color;
                        border: none;
                        border-radius: 999em;
                        display: block;
                        left: .6em;
                    }
                    &::after {
                        border-color: #fff;
                        left: 1em;
                    }
                }
            }
            + label {
                @include button--ghost ($conversion-color);
                border-color: #aaa;
                color: #aaa;
                font-size: 1.25em;
                padding: 16px;
                width: 100%;
                &::before {
                    display: none;
                }
            }
        }
    }
</style>
