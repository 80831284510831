"use strict";

import firebase from "firebase";

export default {
    created() {
        // const data = {
        //     "collection": "settings",
        //     "dispatch": "setSettings",
        // };
        // this.$_getStoreData(data);
        // firebase.firestore().collection("settings").onSnapshot(() => {
        //     this.$_getStoreData(data);
        // });
    },
    "methods": {
        $_getStoreData(data) {
            const collection = data.collection;
            const dispatch = data.dispatch;
            const storeData = {};
            firebase.firestore().collection(collection).get().then(response => {
                response.forEach(doc => {
                    storeData[doc.id] = doc.data();
                });
                this.$store.dispatch(dispatch, storeData);
            }).catch(error => {
                console.error(error.message);
            });
        },
    },
};
